import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Card, CardContent, Badge, Toolbar, AppBar } from "@material-ui/core";
import { ROLES, REQUEST_STATUS } from "../../enums";
import { resetReduxStateRequest } from "../../actions/creators/request";
import { fetchApproversRequestList } from "../../actions/creators/approvers";
import { fetchProvisionerRequestList } from "../../actions/creators/provisioners";
import "../../components/Routes/Routes.css";

const Landing = ({
    //Redux props
    loggedUser,
    requestList,
    provisionerRequestList,
    //Redux creators
    resetReduxStateRequest,
    fetchApproversRequestList,
    fetchProvisionerRequestList
}) => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if (loggedUser.roleId === ROLES.APPROVER || loggedUser.roleId === ROLES.MASTERAPPROVER) {
            fetchApproversRequestList(setLoading);
        }
        else if (loggedUser.roleId === ROLES.ADMIN) {
            fetchApproversRequestList(setLoading);
            fetchProvisionerRequestList(setLoading);
        }
        else if (loggedUser.roleId === ROLES.PROVISIONER) {
            fetchProvisionerRequestList(setLoading);
        }
    }, [fetchApproversRequestList, fetchProvisionerRequestList]);


    var totalApproverCount = requestList.length;
    var totalProvisionerCount = provisionerRequestList.length;

    const buttonStyle = {
        backgroundColor: "#f4f4f4",
        minHeight: 230,
        maxHeight: 230
    };

    const textStyle = {
        fontWeight: 'bold',
        color: "rgb(13, 112, 188)",
        fontFamily: "sans-serif"
    };
    const textStyle1 = {
        fontWeight: 'bold',
        color: "rgb(239, 91, 43)",
        fontFamily: "sans-serif"
    };

    const onClickInItem = (path) => {
        history.push(path);
    };

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col xs={12} className="text-center pt-1">
                        <br></br>
                        <h3 style={textStyle}>WELCOME TO THE GROUPM USER ACCESS PORTAL</h3>
                    </Col>
                </Row>
                <Row style={{justifyContent: "center"}} className="text-center pt-1">
                    <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                        <Card
                            style={buttonStyle}
                            onClick={() => {
                                resetReduxStateRequest();
                                history.push("/NewRequest");
                            }}
                            className="pointer-cursor"
                        >
                            <CardContent>
                                <img
                                    alt="NewRequest"
                                    src="/GroupM_Social_Strategy_RGB.png"
                                    width="120"
                                    height="120"
                                />
                                <h5 className="pt-1">Create a New Access Request</h5>
                            </CardContent>
                        </Card>
                    </Col>
                    <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                        <Card
                            style={buttonStyle}
                            onClick={() => {
                                history.push("/ChangeUserRecords");
                            }}
                            className="pointer-cursor"
                        >
                            <CardContent>
                                <img
                                    alt="ChangeAccess"
                                    src="/GroupM_Digital marketing_RGB.png"
                                    width="120"
                                    height="120"
                                />
                                <h5 className="pt-1">Modify an Existing Access</h5>
                            </CardContent>
                        </Card>
                    </Col>
                    <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                        <Card
                            style={buttonStyle}
                            onClick={() => {
                                history.push("/MyRequests");
                            }}
                            className="pointer-cursor"
                        >
                            <CardContent>
                                <img
                                    alt="MyRequest"
                                    src="/GroupM_Programmatic_RGB.png"
                                    width="120"
                                    height="120"
                                />
                                <h5 className="pt-1">My Requests</h5>
                            </CardContent>
                        </Card>
                    </Col>
                </Row>
                <br></br>
                {loggedUser.roleId == ROLES.STANDARD ? (
                <Row style={{ justifyContent: "center" }} className="text-center pt-1">
                    <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                        <Card
                            style={buttonStyle}
                            onClick={() => {
                                history.push("/LearningResourceCenter");
                            }}
                            className="pointer-cursor"
                        >
                            <CardContent>
                                <img
                                    alt="LearningResourceCenter"
                                    src="/GroupM_Learning_RGB.png"
                                    width="120"
                                    height="120"
                                />
                                <h5 className="pt-1">Learning Resource Center</h5>
                            </CardContent>
                        </Card>
                    </Col>
                    {loggedUser.roleId == ROLES.MASTERAPPROVER ||
                        loggedUser.roleId == ROLES.ADMIN ? (
                    <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                        <Card
                            style={buttonStyle}
                            onClick={() => {
                                history.push("/Scorecards");
                            }}
                            className="pointer-cursor"
                        >
                            <CardContent>
                                <img
                                    alt="Scorecards"
                                    src="/GroupM_SMS_Messaging_RGB.png"
                                    width="120"
                                    height="120"
                                />
                                <h5 className="pt-1">Scorecards</h5>
                            </CardContent>
                        </Card>
                    </Col>
                    ) : (
                    <div/>
                )}
                    <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                        <Card
                            style={buttonStyle}
                            onClick={() => {
                                history.push("/AgencyClients");
                            }}
                            className="pointer-cursor"
                        >
                            <CardContent>
                                <img
                                    alt="AgencyClients"
                                    src="/MyApprovals.png"
                                    width="120"
                                    height="120"
                                />
                                <h5 className="pt-1">What's Included?</h5>
                            </CardContent>
                        </Card>
                    </Col>
                    </Row>
                ) : (
                    <div/>
                )}
                {loggedUser.roleId == ROLES.ADMIN ? (
                        <Row style={{ justifyContent: "center" }} className="text-center py-3">
                            <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                                <Card
                                    style={buttonStyle}
                                    onClick={() => {
                                        history.push("/Scorecards");
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="Scorecards"
                                            src="/GroupM_SMS_Messaging_RGB.png"
                                            width="120"
                                            height="120"
                                        />
                                        <h5 className="pt-1">Scorecards</h5>
                                    </CardContent>
                                </Card>
                            </Col>
                        <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                            <Card
                                style={buttonStyle}
                                onClick={() => {
                                    history.push("/LearningResourceCenter");
                                }}
                                className="pointer-cursor"
                            >
                                <CardContent>
                                    <img
                                        alt="LearningResourceCenter"
                                        src="/GroupM_Learning_RGB.png"
                                        width="120"
                                        height="120"
                                    />
                                    <h5 className="pt-1">Learning Resource Center</h5>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                            <Card
                                style={buttonStyle}
                                onClick={() => {
                                    history.push("/AgencyClients");
                                }}
                                className="pointer-cursor"
                            >
                                <CardContent>
                                    <img
                                        alt="AgencyClients"
                                        src="/MyApprovals.png"
                                        width="120"
                                        height="120"
                                    />
                                    <h5 className="pt-1">What's Included?</h5>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <div/>
                )}
                {loggedUser.roleId == ROLES.APPROVER ||
                    loggedUser.roleId == ROLES.PROVISIONER ? (
                        <Row style={{ justifyContent: "center" }} className="text-center py-3">
                            <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                            <Badge
                                badgeContent={loggedUser.roleId === ROLES.APPROVER || loggedUser.roleId === ROLES.MASTERAPPROVER ? totalApproverCount : totalProvisionerCount}
                                max={50}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Card
                                    style={buttonStyle}
                                    onClick={() => {
                                        if (
                                            loggedUser.roleId === ROLES.APPROVER || loggedUser.roleId === ROLES.MASTERAPPROVER
                                        ) {
                                            history.push("/Approvals");
                                        } else {
                                            history.push("/Provisioners");
                                        }
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="MyApprovals"
                                            src="/GroupM_Certification_RGB.png"
                                            width="120"
                                            height="120"
                                        />
                                        <h5 className="pt-1">My {loggedUser.roleId === ROLES.APPROVER || loggedUser.roleId === ROLES.MASTERAPPROVER ? "Approval" : "Provisioning"} Tasks</h5>
                                    </CardContent>
                                </Card>
                            </Badge>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                            <Card
                                style={buttonStyle}
                                onClick={() => {
                                    if (
                                        loggedUser.roleId === ROLES.APPROVER ||
                                        loggedUser.roleId === ROLES.PROVISIONER
                                    ) {
                                        history.push("/MyApprovalPaths");
                                    } else {
                                        history.push("/MyMasterApprovalPath");
                                    }
                                }}
                                className="pointer-cursor"
                            >
                                <CardContent>
                                    <img
                                        alt="MyRoleDetails"
                                        src="/GroupM_Viewability_RGB.png"
                                        width="120"
                                        height="120"
                                    />
                                    <h5 className="pt-1">My Role Details</h5>
                                </CardContent>
                            </Card>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                                <Card
                                    style={buttonStyle}
                                    onClick={() => {
                                        history.push("/LearningResourceCenter");
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="LearningResourceCenter"
                                            src="/GroupM_Learning_RGB.png"
                                            width="120"
                                            height="120"
                                        />
                                        <h5 className="pt-1">Learning Resource Center</h5>
                                    </CardContent>
                                </Card>
                            </Col>
                            <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                                <Card
                                    style={buttonStyle}
                                    onClick={() => {
                                        history.push("/AgencyClients");
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="AgencyClients"
                                            src="/MyApprovals.png"
                                            width="120"
                                            height="120"
                                        />
                                        <h5 className="pt-1">What's Included?</h5>
                                    </CardContent>
                                </Card>
                            </Col>
                    </Row>
                ) : (
                    <div/>
                )}
                {loggedUser.roleId == ROLES.MASTERAPPROVER ? (
                    <Row style={{ justifyContent: "center" }} className="text-center py-3">
                        <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                            <Badge
                                badgeContent={loggedUser.roleId === ROLES.APPROVER || loggedUser.roleId === ROLES.MASTERAPPROVER ? totalApproverCount : totalProvisionerCount}
                                max={50}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Card
                                    style={buttonStyle}
                                    onClick={() => {
                                        if (
                                            loggedUser.roleId === ROLES.APPROVER || loggedUser.roleId === ROLES.MASTERAPPROVER
                                        ) {
                                            history.push("/Approvals");
                                        } else {
                                            history.push("/Provisioners");
                                        }
                                    }}
                                    className="pointer-cursor"
                                >
                                    <CardContent>
                                        <img
                                            alt="MyApprovals"
                                            src="/GroupM_Certification_RGB.png"
                                            width="120"
                                            height="120"
                                        />
                                        <h5 className="pt-1">My {loggedUser.roleId === ROLES.APPROVER || loggedUser.roleId === ROLES.MASTERAPPROVER ? "Approval" : "Provisioning"} Tasks</h5>
                                    </CardContent>
                                </Card>
                            </Badge>
                        </Col>
                        <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                            <Card
                                style={buttonStyle}
                                onClick={() => {
                                    if (
                                        loggedUser.roleId === ROLES.APPROVER ||
                                        loggedUser.roleId === ROLES.PROVISIONER
                                    ) {
                                        history.push("/MyApprovalPaths");
                                    } else {
                                        history.push("/MyMasterApprovalPath");
                                    }
                                }}
                                className="pointer-cursor"
                            >
                                <CardContent>
                                    <img
                                        alt="MyRoleDetails"
                                        src="/GroupM_Viewability_RGB.png"
                                        width="120"
                                        height="120"
                                    />
                                    <h5 className="pt-1">My Role Details</h5>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                            <Card
                                style={buttonStyle}
                                onClick={() => {
                                    history.push("/Scorecards");
                                }}
                                className="pointer-cursor"
                            >
                                <CardContent>
                                    <img
                                        alt="Scorecards"
                                        src="/GroupM_SMS_Messaging_RGB.png"
                                        width="120"
                                        height="120"
                                    />
                                    <h5 className="pt-1">Scorecards</h5>
                                </CardContent>
                            </Card>
                        </Col>
                       
                    </Row>
                ) : (
                    <div/>
                )}
                {loggedUser.roleId == ROLES.MASTERAPPROVER ? (
                    <Row style={{ justifyContent: "center" }} className="text-center py-3">
                        <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                            <Card
                                style={buttonStyle}
                                onClick={() => {
                                    history.push("/LearningResourceCenter");
                                }}
                                className="pointer-cursor"
                            >
                                <CardContent>
                                    <img
                                        alt="LearningResourceCenter"
                                        src="/GroupM_Learning_RGB.png"
                                        width="120"
                                        height="120"
                                    />
                                    <h5 className="pt-1">Learning Resource Center</h5>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col xs={12} sm={4} md={{ span: 3, offset: 0 }} className="pt-1">
                            <Card
                                style={buttonStyle}
                                onClick={() => {
                                    history.push("/AgencyClients");
                                }}
                                className="pointer-cursor"
                            >
                                <CardContent>
                                    <img
                                        alt="AgencyClients"
                                        src="/MyApprovals.png"
                                        width="120"
                                        height="120"
                                    />
                                    <h5 className="pt-1">What's Included?</h5>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <div/>
                )}

            </Container><br></br>
            <Col md={12}
                style={{ color: "#0d70bc", fontWeight: "bolder" }}>
                <u><p className="pointer-cursor" style={{ float: "right" }}
                    onClick={() => {
                        history.push("/Contact");
                    }}> Contact Us</p></u>
            </Col>
            <br></br>
            <Col md={12}
                style={{ color: "#0d70bc", fontWeight: "bolder" }}>
                <u><p style={{ float: "right", marginRight: "-85px" }}>v.2.46</p></u>
            </Col>
        </React.Fragment>
    );
};

const mapstatetoprops = state => ({
    loggedUser: state.request.loggedUser,
    requestList: state.approvers.requestList.filter(y => y.statusId == REQUEST_STATUS.PENDING),
    provisionerRequestList: state.provisioners.provisionersRequestList.filter(y => y.statusId == REQUEST_STATUS.APPROVED),
});

export default connect(mapstatetoprops, { resetReduxStateRequest, fetchApproversRequestList, fetchProvisionerRequestList })(Landing);
