import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import UserRecordsTable from "./ExtraComponents/Step2UserRecordsTable";
import MainContainer from "../../UI/MainContainer/MainContainer";

const Step2 = ({
  //Redux Props
  listUserRequest
  //Redux creators
}) => {
  return (
    <MainContainer>
      <Container fluid className="mt-3 text-center">
        <Row>
          <Col xs={12} className="pt-1">
            <h3>({listUserRequest.length}) Existing User Access</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }}>
            <p
              style={{
                color: "#808080"
              }}
              className="text-center pt-3"
            >
              The information below represents both active access requests and
              current access records. Before proceeding, please review the
              existing access information for this user to avoid attempts at a
              duplicate request. The portal will not allow you to submit a
              request if a record matches the criteria of an existing access.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <UserRecordsTable />
          </Col>
        </Row>
      </Container>
    </MainContainer>
  );
};

const mapStateToProps = state => ({
  loggedUser: state.request.loggedUser,
  emailSelected: state.request.emailSelected,
  listUserRequest: state.userRequests.allRequests
});

export default connect(mapStateToProps, null)(Step2);
