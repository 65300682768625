import React, { useState } from "react";
import { connect } from "react-redux";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import TooltipForComponent from "../../UI/Tooltip/TooltipForComponent";
import { REQUEST_STATUS } from "../../../enums";
import CustomTable, {
    filterArrayToUniqueKeyValueObject,
    accessDateColumn,
    requestTypeColumn,
} from "../../UI/CustomTable/CustomTable";

import CustomCall from "../../../actions/CustomCall/CustomCall";
import CustomButton from '@material-ui/core/Button';

const MyRequestsTable = ({
    //Props Redux
    listUserRequest,
    loggedUser
}) => {
    //Filter filters helpers
    const [newFilters, setNewFilters] = useState(false); //Know if any filter is applied.
    const [newDataFilters, setNewDataFilters] = useState([]); //Data after filter.
    const [newDataData, setNewDataData] = useState([]); //Filters after filter.
    const [selectedFilterItems, setSelectedFilterItems] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [customAlert, setCustomAlert] = useState(null);
    const [, setShowAlert] = useState(false);
    const [tableRef] = useState(React.createRef());
    //const [ValidationCheck, setValidationCheck] = useState();

    let validationcheck = true;

    //UserEmail,Agency,Practice,Platform,Client,Status
    const columns = [
        {
            field: "requestId",
            title: "Record ID",
        },
        requestTypeColumn(),
        {
            field: "createdDateFormat",
            title: "Request Date",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "createdDateFormat"
            ),
        },
        {
            field: "status.description",
            title: " Request Status",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "status",
                "description"
            ),
            render(row) {
                return (
                    row.status.description == "Submitted" ? <p style={{ color: "green", fontWeight: "bold" }}>{row.status.description}</p> :
                        row.status.description == "Approved" ? <p style={{ color: "orange", fontWeight: "bold" }}>{row.status.description}</p> :
                            <p>{row.status.description}</p>
                )
            }
        },
        {
            field: "isInternal",
            title: "User Type",
            lookup: { false: "External", true: "Internal" },
            render: (row) => {
                return <div>{row.isInternal ? "Internal" : "External"}</div>;
            },
        },
        {
            field: "requestUser.fullName",
            title: "User Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "requestUser",
                "fullName"
            ),
        },
        {
            field: "email",
            title: "User Platform Email",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "email"
            ),
        },
        {
            field: "createdByUser.fullName",
            title: "Requested By",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "createdByUser",
                "fullName"
            ),
        },
        {
            field: "regionName",
            title: "Region",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "regionName"
            ),
        },
        {
            field: "marketName",
            title: "Market",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "marketName"
            ),
        },
        {
            field: "agencyName",
            title: "Agency",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "agencyName"
            ),
        },
        {
            field: "practiceName",
            title: "Practice",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "practiceName"
            ),
        },
        {
            field: "platformName",
            title: "Platform",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "platformName"
            ),
        },
        {
            field: "clientName",
            title: "Client",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "clientName"
            ),
        },
        {
            field: "clientCodeName",
            title: "Client Code",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "clientCodeName"
            ),
        },
        {
            field: "assetName",
            title: "Asset Type",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "assetName"
            ),
        },
        {
            field: "clientAccountName",
            title: "Asset Name",
            cellStyle: { minWidth: 400 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "clientAccountName"
            ),
        },
        {
            field: "platformUserTypeName",
            title: "Platform User Type",
            cellStyle: { minWidth: 300 },
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "platformUserTypeName"
            ),
            render(row) {
                return (
                    row.platformUserType.basicName == "Super Administrator" || row.platformUserType.basicName == "Administrator" ? <p style={{ color: "red" }}>{row.platformUserType.description}</p> :
                        <p>{row.platformUserType.description}</p>
                )
            }
        },
        accessDateColumn(),
        {
            field: "comments",
            title: "Requestor Comments",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "comments"
            ),
            cellStyle: { minWidth: 300 },
           
        },
        {
            field: "approverName",
            title: "Approver Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "approverName"
            ),
        },
        {
            field: "approverComment",
            title: "Approver Comments",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "approverComment"
            ),
            cellStyle: { minWidth: 300 },
        },
        {
            field: "provisionerName",
            title: "Provisioner Name",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "provisionerName"
            ),
        },
        {
            field: "provisionerComment",
            title: "Provisioner Comments",
            lookup: filterArrayToUniqueKeyValueObject(
                newFilters ? newDataFilters : listUserRequest,
                "provisionerComment"
            ),
            cellStyle: { minWidth: 300 },
        },
    ];

    const CancelSelectedRequest = (selectedRows) => {
        if (selectedRows.length > 0) {
            var r = window.confirm("Are you sure want to cancel the selected request(s)?");
            if (r == true) {

                var requestids = [];

                for (let i = 0; i < selectedRows.length; i++) {
                    requestids.push(selectedRows[i].requestId);
                }

                CustomCall(
                    "post",
                    `/api/Request/CancelMultipleRequest/`,
                    requestids,
                    (response) => {
                        if (response.data.succeeded) {
                            alert("Request cancelled successfully!");
                            window.location.reload(false);
                        }
                    },
                    setCustomAlert,
                    setShowAlert
                )
            } else {

            }
        }
    }

    const onSelectionChange = (rows, row) => {
        setSelectedRows(rows);
        getButtonDisabled(rows);
    };

    const getButtonDisabled = (rows) => {
        let allsamecount = 0;
        if (rows !== undefined) {
            if (rows.length === 0) {
                return true
            }
            else {
                for (let i = 0; i < rows.length; i++) {
                    if (rows[i].createdBy === loggedUser.userId && rows[i].status.statusId === REQUEST_STATUS.PENDING) {
                        allsamecount++;
                    }
                    else {
                        validationcheck = true;
                    }
                }
            }
        }
        else {
            validationcheck = true;
        }
        if (allsamecount === rows.length) {
            validationcheck = false;
        }
        return validationcheck;
    };

    return (
        <React.Fragment>
            <Col xs={12} className="text-left pt-2">
                <TooltipForComponent
                    text="Cancel a task available only when you are the requestor and the status is submitted"
                    placement="right"
                >
                    <span>
                        <CustomButton
                            disabled={getButtonDisabled(selectedRows)}
                            onClick={() => {
                                CancelSelectedRequest(selectedRows);
                            }}

                            variant="contained" color="secondary"
                        >
                            Cancel selected requests ({selectedRows.length})
                        </CustomButton>
                    </span>
                </TooltipForComponent>
            </Col>
            <Col xs={12} className="pt-4">
                <CustomTable
                    data={newFilters ? newDataData : listUserRequest}
                    columns={columns}
                    title={"My Requests"}
                    tableRef={tableRef}
                    showSearchBar={true}
                    showSelectionColumn={true}
                    fillHeight={true}
                    paddingTop={0}
                    onSelectionChange={onSelectionChange}
                    selectedFilterItems={selectedFilterItems}
                    setSelectedFilterItems={setSelectedFilterItems}
                    offlineFilterHelpers={{
                        originalData: listUserRequest,
                        setNewFilters,
                        setNewDataFilters,
                        setNewDataData,
                    }}
                    headerColor="rgb(255 238 203)"
                    fontColor="black"
                />
            </Col>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    listUserRequest: state.userRequests.allRequests,
    loggedUser: state.request.loggedUser
});

export default connect(mapStateToProps, null)(MyRequestsTable);
